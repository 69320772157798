export const UBERHAUS_DATA = {
  NETWORK: '0x64',
  NETWORK_NAME: 'xDai',
  ADDRESS: '0x4bec8e49aa44bb520bd5e29d6358e22464b5eb68',
  STAKING_TOKEN: '0xb0c5f3100a4d9d9532a4cfd68c55f1ae8da987eb',
  STAKING_TOKEN_SYMBOL: 'HAUS',
  STAKING_TOKEN_DECIMALS: '18',
  MINION_REWARDS_FACTOR: '100',
  BURNER_TOKENS:
    '0xe91d153e0b41518a2ce8dd3d7944fa863463a97d, 0xb0c5f3100a4d9d9532da4cfd68c55f1ae8da987eb',
};

// KOVAN TESTER
// export const UBERHAUS_DATA = {
//   NETWORK: '0x2a',
//   NETWORK_NAME: 'Kovan',
//   ADDRESS: '0x96714523778e51b898b072089e5615d4db71078e',
//   STAKING_TOKEN: '0xAb5cC910998Ab6285B4618562F1e17f3728af662',
//   STAKING_TOKEN_SYMBOL: 'fHAUS',
//   STAKING_TOKEN_DECIMALS: '18',
//   MINION_REWARDS_FACTOR: '100',
//   BURNER_TOKENS:
//     '0xd0a1e359811322d97991e03f863a0c30c2cf029c, 0xAb5cC910998Ab6285B4618562F1e17f3728af662',
// };
