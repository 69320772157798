export const nftSortOptions = [
  {
    name: 'Value',
    value: 'value',
  },
  {
    name: 'Date Created',
    value: 'dateCreated',
  },
  {
    name: 'Expiring Auctions',
    value: 'expiringAuctions',
  },
];

export const nftFilterOptions = [
  // {
  //   name: 'For Sale',
  //   value: 'forSale',
  //   type: 'main',
  // },
  // {
  //   name: 'Has Offer',
  //   value: 'hasOffer',
  //   type: 'main',
  // },
];
