export const PUBLISHERS = {
  DAOHAUS: {
    logo: 'dh',
    name: 'DAOhaus',
    link: 'https://daohaus.club/',
    daoData: {
      network: '0x64',
      address: '0xef3d8c4fbb1860fceab16595db7e650cd5ad51c1',
    },
  },
};
